<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 138px);">
        <template v-if="id_producto">
            <div class="row mx-0 align-items-center p-2">
                <p class="col-auto text-general f-16">
                    Precios del producto en todos los CEDIS
                </p>
                <div class="col-auto px-0">
                    <div v-if="$can('boton_productos_cambiar_precio_general')" class="btn-action border cr-pointer d-middle-center text-general" @click="cambiarValor">
                        <i class="icon-pencil-outline f-18" />
                    </div>
                </div>
            </div>
            <div v-for="(cedis, c) in dataCedis" :key="c" class="row mx-2 my-2 card-cedis pt-2">
                <div class="col pr-0">
                    <p class="text-general f-18 f-500">
                        {{ cedis.nombre }}
                    </p>
                    <p class="text-general f-15">
                        {{ separadorNumeroDecimales(cedis.valor,cedis.decimales) }}
                    </p>
                    <div class="row mx-0 mt-3">
                        <div class="col px-1 d-flex flex-column justify-content-center align-items-center">
                            <el-tooltip placement="bottom" content="Stock" effect="light">
                                <i class="icon-package-variant-close text-general f-20" />
                            </el-tooltip>
                            <p class="text-general">
                                {{ agregarSeparadoresNumero(cedis.stock,0,false) }}
                            </p>
                        </div>
                        <div class="col px-1 d-flex flex-column justify-content-center align-items-center">
                            <el-tooltip placement="bottom" content="Stock Mínimo Cedis" effect="light">
                                <i class="icon-warehouse f-22 text-general" />
                            </el-tooltip>
                            <p class="text-general">
                                {{ cedis.stock_minimo_cedis }}
                            </p>
                        </div>
                        <div class="col px-1 d-flex flex-column justify-content-center align-items-center">
                            <el-tooltip placement="bottom" content="Stock Mínimo Vendedor" effect="light">
                                <i class="icon-account-check f-22 text-general" />
                            </el-tooltip>
                            <p class="text-general">
                                {{ cedis.stock_minimo_tienda }}
                            </p>
                        </div>
                        <div class="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <el-tooltip placement="bottom" content="Disponible en el cedis" effect="light">
                                <i class="icon-format-list-checks f-22 text-general" />
                            </el-tooltip>
                            <el-checkbox v-model="cedis.disponible_cedi" class="check-dark" :disabled="!$can('botones_productos_gestionar_cedis')" :true-label="1" :false-label="0" @change="updateDisponibleCedi(cedis)" />
                        </div>
                        <div class="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <el-tooltip placement="bottom" content="Permite Vender Sin Stock" effect="light">
                                <i class="icon-cash-lock-open f-18 text-general" />
                            </el-tooltip>
                            <el-checkbox v-model="cedis.venta_sin_stock" class="check-dark" :true-label="1" :false-label="0" :disabled="cedis.disabled || !$can('botones_productos_gestionar_cedis')" @change="venderSinStock(cedis)" />
                        </div>
                        <div class="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <el-tooltip placement="bottom" content="El inventario lo tiene directamente el proveedor" effect="light">
                                <i class="icon-package-variant f-22 text-general" />
                            </el-tooltip>
                            <el-checkbox v-model="cedis.inventario_proveedor" class="check-dark" :disabled="!$can('botones_productos_gestionar_cedis')" :true-label="1" :false-label="0" @change="updateInventarioProveedor(cedis)" />
                        </div>
                        <div class="col px-0 d-flex flex-column justify-content-center align-items-center">
                            <el-tooltip placement="bottom" content="Proveedor seleccionado" effect="light">
                                <i class="icon-proveedor f-22 text-general" />
                            </el-tooltip>
                            <el-checkbox v-model="cedis.proveedor_favorito" class="check-red" :true-label="1" :false-label="0" disabled />
                        </div>
                    </div>
                </div>
                <div class="col-auto px-2">
                    <el-tooltip v-if="$can('botones_productos_gestionar_cedis')" placement="bottom" content="Editar Valor" effect="light">
                        <div class="btn-action border cr-pointer d-middle-center text-general" @click="modalEditarValores(cedis)">
                            <i class="icon-pencil-outline f-18 cr-pointer" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </template>
        <!-- Partials -->
        <modal-cambiar-valor-cedis ref="CambiarCedis" @actualizar="listarCedisTabla" />
        <modalEditarValores ref="modalEditarValores" @actualizar="listarCedisTabla" />
        <modal-cambiar-valor ref="modalCambiarValor" />
    </section>
</template>

<script>
import CedisProductos from '~/services/cedis/cedis_productos'
import {mapGetters} from 'vuex'

export default {
    components: {
        modalCambiarValorCedis: () => import('../partials/modalCambiarValorCedis'),
        modalEditarValores: () => import('../partials/modalEditarValores'),
        modalCambiarValor: () => import('../partials/modalCambiarValor')
    },
    data(){
        return{
            loading: false,
            list: false,
            money: false,
            titulos_filas:[],
            dataCedis:[]
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
        })
    },
    watch:{
        id_producto(val){
            if(val){
                this.titulos_filas= []
                this.dataCedis= []
                this.listarCedisTabla()
            }
        },
    },
    methods: {
        cambiarValorTodos(){
            this.$refs.CambiarCedis.toggle()
        },
        modalEditarValores(info){
            this.$refs.modalEditarValores.toggle(info)
        },
        async listarCedisTabla(){
            try {
                const {data} = await CedisProductos.tablaCedisProductos(this.id_producto)
                this.dataCedis = data.cedis
                const titulos = data.cedis.map(e => {
                    return {id: e.id, nombre: e.nombre}
                })
                this.titulos_filas = titulos
            } catch (e){
                this.error_catch(e)
            }
        },
        cambiarValor(){
            let cedisValidos = this.dataCedis.map(el => ({...el, promo_valor_formateado: this.separadorNumero(el.promo_valor)}))
            console.log({cedisValidos});
            this.$refs.modalCambiarValor.toggle({ cedisValidos, idProducto: this.id_producto });
        },
        async updateInventarioProveedor(info){
            try {
                const payload = {
                    id_cedis: info.id,
                    id_producto: this.id_producto,
                    inventario_proveedor: info.inventario_proveedor
                }
                this.loading= true
                const {data} = await CedisProductos.updateInventarioProveedor(payload)
                this.loading= false
                this.notificacion('Mensaje', data.Message, 'success')
                // info.inventario_proveedor = data.cedisProducto.estado === 0

            } catch (e){
                info.inventario_proveedor = !info.inventario_proveedor
                this.loading= false
                this.error_catch(e)
            }
        },
        async updateDisponibleCedi(info){
            try {
                const payload = {
                    id_cedis: info.id,
                    id_producto: this.id_producto,
                    estado: info.disponible_cedi
                }
                this.loading= true
                const {data} = await CedisProductos.updateDisponibleCedi(payload)
                this.loading= false
                this.notificacion('Mensaje', data.Message, 'success')
                info.disabled = data.cedisProducto.estado === 0
                info.id_cedis_productos = data.cedisProducto.id

            } catch (e){
                info.disponible_cedi = true
                console.log('catch');
                this.loading= false
                this.error_catch(e)
            }
        },
        async venderSinStock(info){
            try {
                const payload = {
                    id_cedis_productos: info.id_cedis_productos,
                    estado: info.disponible_cedi,
                    venta_sin_stock: info.venta_sin_stock
                }
                this.loading= true
                const {data} = await CedisProductos.venderSinStock(payload)
                this.loading= false
                this.notificacion('Mensaje', data.Message, 'success')
            } catch (e){
                this.loading= false
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.edit-hover{
    i{
        color: var(--color-general2);
    }
    &:hover{
        i{ color: var(--text-general) !important; }
    }
}
.nombre-cedis{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
.hover-theme:hover{
    color: var(--text-general) !important;
}
.card-cedis{
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    min-height: 140px;
}
</style>
